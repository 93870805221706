@import '../styles/entry.scss';

.nav {
  composes: bg-primary from global;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: $nav-link-height;
}

.navbar {
  composes: dFlex alignItemsCenter justifyContentBetween mxAuto from '../styles/utils.module.scss';
  position: relative;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  // Only max width on lg and above to fit nav
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, xl);
  }
}

.brand {
  margin-right: $navbar-padding-x;
  font-size: $navbar-brand-font-size;
  font-weight: 500;
  color: $navbar-dark-active-color;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  white-space: nowrap;

  @include hover-focus {
    color: $navbar-dark-active-color;
    text-decoration: none;
  }
}

.button {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.navContainer {
  composes: dFlex from '../styles/utils.module.scss';
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.navOpen {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background: $primary;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    position: static;
  }
}
