@import '../styles/entry.scss';

.footer {
  composes: py4 from '../styles/utils.module.scss';
  background: $white;
  color: $black
}

.desc {
  text-align: center;
}

.orange {
  color: orange;
}