@import '../styles/entry.scss';

.parent {
  background: $primary;
  background: linear-gradient(
    3.5deg,
    rgba(63, 20, 38, 1) 0%,
    rgba(64, 32, 96, 1) 35%,
    rgba(65, 37, 21, 1) 100%
  );
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
}
