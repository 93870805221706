$font-family-sans-serif: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$primary: #412515;
$secondary: #656565;
$pink: #cf057c;
$purple: #3f148a;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
);

$input-btn-focus-width: 0.1rem;
$input-btn-focus-color: rgba($primary, 0.75);

$label-margin-bottom: 0.25rem;
$nav-link-padding-x: 0.8rem;
$nav-link-padding-y: 1rem;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

h2, .h2 {
  font-size: 2.2rem;
}

h3, .h3 {
  font-size: 1.9rem;
}

.slick-prev {
  left: 15px;
  top: calc(50% - 42px) !important;
  z-index: 1
}

.slick-next {
  right: 15px;
  top: calc(50% - 42px) !important;
  z-index: 1
}

.btn-group > .btn.active {
  z-index: 0 !important;
}
