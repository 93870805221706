@import '../styles/entry.scss';

// Container and rows
.container,
.containerFluid {
  @include make-container();
}

.container {
  @include make-container-max-widths();
}

.row {
  @include make-row();
}

// Margin and Padding
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t#{$size},
    .#{$abbrev}y#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r#{$size},
    .#{$abbrev}x#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b#{$size},
    .#{$abbrev}y#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l#{$size},
    .#{$abbrev}x#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

// Some special margin utils
.mAuto {
  margin: auto !important;
}
.mtAuto,
.myAuto {
  margin-top: auto !important;
}
.mrAuto,
.mxAuto {
  margin-right: auto !important;
}
.mbAuto,
.myAuto {
  margin-bottom: auto !important;
}
.mlAuto,
.mxAuto {
  margin-left: auto !important;
}

// Display utils
.dFlex {
  display: flex;
}

// Flex flex-direction utils;
.flexRow {
  flex-direction: row !important;
}
.flexColumn {
  flex-direction: column !important;
}
.flexRowReverse {
  flex-direction: row-reverse !important;
}
.flexColumnReverse {
  flex-direction: column-reverse !important;
}

.flexWrap {
  flex-wrap: wrap !important;
}
.flexNowrap {
  flex-wrap: nowrap !important;
}
.flexWrapReverse {
  flex-wrap: wrap-reverse !important;
}
.flexFill {
  flex: 1 1 auto !important;
}
.flexGrow0 {
  flex-grow: 0 !important;
}
.flexGrow1 {
  flex-grow: 1 !important;
}
.flexShrink0 {
  flex-shrink: 0 !important;
}
.flexShrink1 {
  flex-shrink: 1 !important;
}

// Flex justify-content utils
.justifyContentStart {
  justify-content: flex-start !important;
}
.justifyContentEnd {
  justify-content: flex-end !important;
}
.justifyContentCenter {
  justify-content: center !important;
}
.justifyContentBetween {
  justify-content: space-between !important;
}
.justifyContentAround {
  justify-content: space-around !important;
}

// Flex align-items utils
.alignItemsStart {
  align-items: flex-start !important;
}
.alignItemsEnd {
  align-items: flex-end !important;
}
.alignItemsCenter {
  align-items: center !important;
}
.alignItemsBaseline {
  align-items: baseline !important;
}
.alignItemsStretch {
  align-items: stretch !important;
}

// Flex align-content utils
.alignContentStart {
  align-content: flex-start !important;
}
.alignContentEnd {
  align-content: flex-end !important;
}
.alignContentCenter {
  align-content: center !important;
}
.alignContentBetween {
  align-content: space-between !important;
}
.alignContentAround {
  align-content: space-around !important;
}
.alignContentStretch {
  align-content: stretch !important;
}

// Flex align-self utils
.alignSelfAuto {
  align-self: auto !important;
}
.alignSelfStart {
  align-self: flex-start !important;
}
.alignSelfEnd {
  align-self: flex-end !important;
}
.alignSelfCenter {
  align-self: center !important;
}
.alignSelfBaseline {
  align-self: baseline !important;
}
.alignSelfStretch {
  align-self: stretch !important;
}
