@import '../styles/entry.scss';

.section {
  padding-top: #{$spacer * 2};
  padding-bottom: #{$spacer * 2};

  @include media-breakpoint-up(xl) {
    padding-top: $spacer * 3;
  }
}

.header {
  margin-left: 5rem;
  margin-right: 5rem;
}

.hero {
  composes: containerFluid dFlex flexWrap alignItemsStart justifyContentCenter from '../styles/utils.module.scss';
  composes: section;
  position: relative;
  color: $white !important;
  text-align: center;
}

.logo {
  width: 50%;
  max-width: 16rem;
  font-size: $font-size-base;
  font-weight: 500;
  text-anchor: middle;
  box-shadow: 3px 3px 3px rgba(28, 28, 28, 0.5);
}

.link {
  color: $white;
  text-align: center;

  @include hover-focus {
    text-decoration: none;
    color: $navbar-dark-hover-color;
  }
}

.bigButton {
  composes: mt2 mb3 from '../styles/utils.module.scss';
}

.bigButtonText {
}

.bigLink, .bigLink:hover {
  color: white;
}

.title {
  font-size: $h1-font-size;

  @include media-breakpoint-up(md) {
    font-size: $display2-size;
  }
}

.detailsContainer {
  composes: section;

  &:nth-child(even) {
    background: $gray-200;
  }
}

.sectionHeader {
  color: $primary;
  text-align: center;
}

.main {
  display: flex;
  flex: 1;
}
