@import './styles/entry.scss';
@import './styles/bootstrap.scss';

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url('/fonts/muli-reg.woff2') format('woff2'),
    url('/fonts/muli-reg.woff') format('woff');
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url('/fonts/muli-med.woff2') format('woff2'),
    url('/fonts/muli-med.woff') format('woff');
}

html,
body,
#root {
  height: 100%;
}

html {
  -ms-overflow-style: scrollbar;
}

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

.bg-primary {
  background: $primary;
}
